import { defineStore } from "pinia";
import router from "@/router";
import publicApi from "../api/publicApi";
import * as Sentry from "@sentry/vue";
import type { CurrentUser, GroupMembership, UserNavObject } from "@/types";
import type { MenuItem } from "primevue/menuitem";

interface State {
  user: CurrentUser | null;
  isLoading: boolean;
}

/* 
  Handles authentication for the current user
  See documentation: https://k2united.atlassian.net/l/cp/Zzw8kqdj
*/
export const useUserStore = defineStore("user", {
  state: (): State => {
    return {
      user: null,
      isLoading: false
    };
  },

  getters: {
    currentUser: (state: State) => state.user,

    isAuthenticated: (state: State) => (state.user ? state.user.is_authenticated : false),

    acceptedTerms: (state: State) =>
      state.user ? state.user.user_accepted_terms_and_conditions : false,

    isPasswordResetRequired: (state: State) =>
      state.user ? state.user.password_reset_required : false,

    isActivationRequired: (state: State) =>
      state.user ? !!state.user?.activation_required : false,

    /**
     * Indicates if current user is an admin of the root group/organization.
     * @returns boolean
     */
    isRootOrganizationAdmin: (state: State) => {
      return state.user?.current_org?.role?.generic_role?.permission_type.value === "ADMIN";
    },

    /**
     * Indicates if user is an admin of any organization in the current group/organization tree.
     * @returns boolean
     */
    isOrganizationAdmin(state: State): boolean {
      if (this.isRootOrganizationAdmin) {
        return this.isRootOrganizationAdmin;
      }
      // Check if the user has admin group membership in any group that is also an organization
      const orgAdminMembership = state.user?.current_org?.group_memberships.find(
        (groupMembership) => {
          if (
            groupMembership.role.generic_role.permission_type === "ADMIN" &&
            groupMembership.group_organization_id
          ) {
            return groupMembership;
          }
        }
      );
      return orgAdminMembership !== undefined;
    },

    isAccountOrganizationAdmin(state: State): boolean {
      const currentOrgId = state.user?.current_org?.organization_id;
      const isAccountOrg =
        state.user?.organizations?.find((org) => org.id === currentOrgId)?.system_classification ===
        "ACCOUNT";

      return this.isOrganizationAdmin && isAccountOrg;
    },

    isOnlyLearner: (state: State) => {
      const filtered = state.user?.current_org?.group_memberships.filter(
        (groupMembership) => groupMembership.role.generic_role.permission_type === "LEARNER"
      );

      return (
        filtered?.length !== undefined &&
        // looks for role other than LEARNER
        state.user?.current_org?.group_memberships.length == filtered.length &&
        // looks if any role was LEARNER
        filtered.length > 0
      );
    },

    isInstructor: (state: State) => {
      const isNotIndividual = state.user?.current_org?.org_type_category.value !== "INDIVIDUAL";
      const isAdmin = state.user?.current_org?.group_memberships.some((membership) => {
        return membership.role.generic_role.permission_type === "ADMIN";
      });

      return isNotIndividual && isAdmin;
    },

    isOrgIndividual: (state: State) => {
      return isCurrentOrgIndividual(state);
    },

    hasNoUserRoles: (state: State) => {
      if (state.user) {
        if (state.user?.current_org) {
          return state.user?.current_org?.group_memberships.length === 0;
        } else {
          return true;
        }
      }
      return false;
    },

    isEcommerceDisabled: (state: State) => {
      if (state.user?.current_org?.security_settings) {
        return state.user.current_org.security_settings.disable_ecommerce;
      }

      return false;
    },

    getNavInfo(state: State): UserNavObject[] {
      // Home
      const userNavArray: UserNavObject[] = [
        {
          id: "home-nav-link",
          title: "Home",
          icon: "",
          route: { name: "Home" },
          ariaLabel: "",
          children: []
        }
      ];

      // Gradebook
      if (state.user?.has_gradebooks) {
        userNavArray.push({
          id: "gradebook-nav-link",
          title: "Gradebook",
          icon: "",
          route: { name: "GradebookSelection" },
          ariaLabel: "",
          children: []
        });
      }
      // Manage
      if (this.isOrganizationAdmin && !this.isOrgIndividual) {
        const manageNavObject: UserNavObject = {
          id: "manage-nav-link",
          title: "Manage",
          icon: "",
          route: {},
          ariaLabel: "Header Manage Navigation",
          children: []
        };

        const groupsNavArray: MenuItem = {
          id: "group-sub-nav-link",
          class: "group-sub-nav-link sub-menu__text",
          label: "Groups",
          command: () => {
            router.push({
              name: "Group",
              params: { id: state.user?.current_org?.top_level_groups[0].id }
            });
          }
        };

        if (
          state.user &&
          state.user?.current_org &&
          state.user?.current_org?.top_level_groups.length > 1
        ) {
          groupsNavArray.command = () => {
            router.push({ name: "GroupSelectionView" });
          };
        }

        manageNavObject.children?.push(groupsNavArray);
        const orgAdminMembershipWithSubOrgs = state.user?.current_org?.group_memberships.find(
          (groupMembership) => {
            if (
              hasAdminGroupPermission(groupMembership.role.permissions) &&
              groupMembership.group_organization_id &&
              groupMembership.has_descendent_organizations &&
              !groupMembership.can_accept_license
            ) {
              return groupMembership;
            }
          }
        );

        const seatsNav: MenuItem = {
          id: "seats-nav-link",
          class: "seats-sub-nav-link sub-menu__text",
          label: "Seats",
          command: () => {
            router.push({
              name: "OrganizationSeatsProducts",
              params: { organizationId: state.user?.current_org?.organization_id }
            });
          }
        };
        manageNavObject.children?.push(seatsNav);

        if (orgAdminMembershipWithSubOrgs !== undefined) {
          const licenseNavArray: MenuItem = {
            id: "license-sub-nav-link",
            class: "license-sub-nav-link sub-menu__text",
            label: "Licenses",
            command: () => {
              router.push({
                name: "LicensesView",
                params: { id: orgAdminMembershipWithSubOrgs.group_organization_id }
              });
            }
          };
          manageNavObject.children?.push(licenseNavArray);
        }
        userNavArray.push(manageNavObject);
      }

      // Groups
      if (!this.isOrganizationAdmin && !this.isOrgIndividual) {
        if (state.user && state.user?.current_org && state.user?.current_org?.top_level_groups) {
          if (state.user?.current_org?.top_level_groups.length > 0) {
            let group_found = false;

            for (let i = 0; i < state.user?.current_org?.group_memberships?.length; i++) {
              if (
                state.user?.current_org.group_memberships[i].role.permissions.includes(
                  "change_group"
                )
              ) {
                group_found = true;
                break;
              }
            }

            if (group_found) {
              const standaloneGroupsNavArray: UserNavObject = {
                id: "group-nav-link",
                title: "Groups",
                icon: "",
                route: {
                  name: "Group",
                  params: { id: state.user?.current_org?.top_level_groups[0].id }
                },
                ariaLabel: "",
                children: []
              };
              if (state.user?.current_org?.top_level_groups.length > 1) {
                standaloneGroupsNavArray.route = { name: "GroupSelectionView" };
              }
              userNavArray.push(standaloneGroupsNavArray);
            }
          }
        }
      }

      // Courses
      if (state.user?.has_enrollment) {
        userNavArray.push({
          id: "courses-nav-link",
          title: "Courses",
          icon: "",
          route: { name: "CoursesView" },
          ariaLabel: "",
          children: []
        });
      }

      // Orders
      if (hasOrgPermission(state, "view_order") || state.user?.has_placed_order) {
        userNavArray.push({
          id: "orders-nav-link",
          title: "Orders",
          icon: "",
          route: { name: "OrdersView" },
          ariaLabel: "",
          children: []
        });
      }

      // Users
      if (this.isInstructor) {
        userNavArray.push({
          id: "users-nav-link",
          title: "Users",
          icon: "",
          route: { name: "UsersView" },
          ariaLabel: "",
          children: []
        });
      }

      return userNavArray;
    },

    getProfileNavInfo: (state: State) => {
      const userProfileNavArray: UserNavObject = {
        id: "profile-nav-link",
        title: `${
          state.user?.first_name != null &&
          state.user?.first_name != "" &&
          state.user?.first_name != undefined
            ? state.user?.first_name
            : "User"
        }`,
        icon: "",
        route: {},
        ariaLabel: "Header Profile Navigation",
        children: [] as MenuItem[]
      };

      // Profile
      const profileNavArray: MenuItem = {
        class: "profile-sub-nav-link",
        label: "Profile",
        icon: "fa-solid fa-user-circle",
        command: () => {
          window.open(`${import.meta.env.VITE_APP_CAMPUS_URL}/user_properties.k2`, "_blank");
        }
      };
      userProfileNavArray.children?.push(profileNavArray);

      // Settings
      if (hasOrgPermission(state, "change_organization")) {
        const settingsNavArray: MenuItem = {
          class: "settings-sub-nav-link",
          label: "Settings",
          icon: "fa-solid fa-gear",
          command: () => {
            router.push({
              name: "GroupSettings",
              params: { id: state.user?.current_org?.group_id }
            });
          }
        };
        userProfileNavArray.children?.push(settingsNavArray);
      }

      // Switch Dashboard
      if (state.user?.organizations !== undefined && state.user?.organizations.length > 1) {
        const settingsNavArray: MenuItem = {
          class: "org-switch-sub-nav-link",
          label: "Switch Dashboard",
          icon: "fa-solid fa-shuffle",
          command: () => {}
        };
        userProfileNavArray.children?.push(settingsNavArray);
      }

      // Shipping Address
      if (state.user?.enrolled_in_ship_to_students_group) {
        const settingsNavArray: MenuItem = {
          class: "shipping-address-sub-nav-link",
          label: "Shipping Address",
          icon: "fa-solid fa-truck",
          command: () => {
            router.push({ name: "UserShippingAddress" });
          }
        };
        userProfileNavArray.children?.push(settingsNavArray);
      }

      //Help
      const helpNavArray: MenuItem = {
        class: "help-sub-nav-link sub-menu__border",
        label: "Help",
        icon: "fa-solid fa-circle-question",
        command: () => {
          if (
            state.user?.current_org?.security_settings?.customer_support_level === "ADMIN_ONLY" &&
            (state.user?.current_org?.role === null ||
              state.user?.current_org?.role?.generic_role?.permission_type?.value === "LEARNER")
          ) {
            router.push({ name: "Help" });
          } else {
            window.open("https://www.careersafeonline.com/support", "__blank")?.focus();
          }
        }
      };
      userProfileNavArray.children?.push(helpNavArray);

      //Sign Out
      const signOutNavArray: MenuItem = {
        class: "sign-out-sub-nav-link",
        label: "Sign Out",
        icon: "fa-solid fa-sign-out",
        command: () => {
          router.push({ name: "SignOut" });
        }
      };
      userProfileNavArray.children?.push(signOutNavArray);

      return userProfileNavArray;
    }
  },

  actions: {
    async getUser() {
      try {
        this.isLoading = true;
        const userResponse = await publicApi.get("/utils/users", {
          withCredentials: true
        });

        this.user = userResponse.data.data;
        this.isLoading = false;
      } catch (error) {
        router.push({ name: "SignIn" });
      }
    },

    async getToken() {
      try {
        await publicApi.get("/auth/generate_csrf_token", {
          withCredentials: true
        });
      } catch (error) {
        router.push({ name: "SignIn" });
      }
    },

    async handleAuthentication() {
      const currentRoute = router.currentRoute.value;

      // Very likely this gets ran several times in a row from several failed
      // queries on the same page, so we pass only the original path along to
      // each as a redirect
      const redirectRegex = /(?:redirect=)([^&]*)/;
      // Regex will match two groups the second group will be just the path
      let redirect = currentRoute.fullPath.match(redirectRegex)
        ? currentRoute.fullPath.match(redirectRegex)?.[1]
        : undefined;
      // Get the current user object and store it global state
      await this.getUser();

      // If not authenticated, redirect to sign in page
      if (!this.isAuthenticated) {
        redirect = redirect ? redirect : currentRoute.path;
        router.push({ name: "SignIn", query: { redirect: redirect } });
        return;
      }

      // Get the CSRF token
      await this.getToken();

      // Identify user in Sentry
      Sentry.getGlobalScope().setUser({ id: this.user?.id ? this.user.id : undefined });

      // Redirect to home page, the shipping address page, or "redirect" param
      if (this.user?.prompt_for_shipping_address) {
        redirect = redirect ? redirect : "/";
        router.push({
          name: "UserShippingAddress",
          query: { redirect: redirect, intro: "sign-in" }
        });
      } else if (redirect && redirect !== "/signout") {
        delete currentRoute.query.redirect;
        router.push({ path: redirect, query: currentRoute.query });
      } else {
        router.push({ name: "Home" });
      }
    },

    signOut() {
      // Remove the user from global state
      this.user = null;

      // Redirect to the CS campus sign off page
      window.location.replace(`${import.meta.env.VITE_APP_CAMPUS_URL}/signoff.k2`);
    }
  },

  persist: true
});

// Helper Functions
export const hasOrgPermission = (state: State, permission: string) => {
  if (state.user && state.user?.current_org && state.user?.current_org) {
    if (!isCurrentOrgIndividual(state)) {
      const hasPermission = (element: GroupMembership) =>
        element.group_organization_id !== null && element.role.permissions.includes(permission);
      return state.user?.current_org?.group_memberships.some(hasPermission);
    }
  }
  return false;
};

/**
 * Indicate if user has admin permissions for a group given a list of permissions.
 * @param permissions array of permission codenames
 * @returns Boolean
 */
export const hasAdminGroupPermission = (permissions: String[]) => {
  const adminPermission = "change_group";
  const hasPermission = (permission: String) => permission === adminPermission;
  return permissions.some(hasPermission);
};

export const isCurrentOrgIndividual = (state: State) => {
  if (state.user && state.user?.current_org && state.user?.current_org) {
    return state.user?.current_org.org_type_category.value === "INDIVIDUAL";
  }
  return false;
};
